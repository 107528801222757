import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import Logo from '../../assets/images/logoGiga.png';

import './styles.css';

function NavBar() {

  const message = "Internet, TV por Assinatura, Telefonia Fixa, Internet Rural";

  const [whatsAppLink, setWhatsAppLink] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const baseURL = "https://wa.me/556222000000";
    const messageParam = encodeURIComponent(`Olá, entre para falar sobre: ${message}`);

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
      setWhatsAppLink(`whatsapp://send?phone=556222000000&text=${messageParam}`);
    } else {
      setWhatsAppLink(`${baseURL}?text=${messageParam}`);
    }
  }, [message]);

  return (
    <>
      <div className='nav-top'>
        <Navbar className="bg-body-tertiary container topo">
          <Container>
            <a href="#login" className="nav-item">Para Você</a>
            <a href="#login" className="nav-item">Empresas</a>
            <a href="#login" className="nav-item">Rural</a>
            <span className="separator">| {'\u00A0'}</span>
            <Navbar.Brand href="#home" className="nav-item"><i className="fa fa-phone" aria-hidden="true"> </i> 0800 62 2200</Navbar.Brand>

            <Navbar.Collapse className="justify-content-end social-icons">
              <Navbar.Text>
                <div className="social-media">
                  <a href={whatsAppLink} target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                  <a target="_blank" href="https://www.facebook.com/gigabytetelecom"><i className="fa fa-facebook-official" aria-hidden="true"> </i></a>
                  <a target="_blank" href="https://www.instagram.com/gigabytetelecom/"><i className="fa fa-instagram" aria-hidden="true"> </i></a> 
                  Central do Assinante:
                  <a href="https://gigabytetelecom.com.br:2096/" target="_blank" className="nav-item"><i className="fa fa-envelope" aria-hidden="true"></i></a>
                </div>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <Navbar className='down' expand="lg">
        <Container>
          <Navbar.Brand className="me-auto" href="/"><div className='logo'><img src={Logo} alt='logo' /></div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown title="Atendimento" id="basic-nav-dropdown">
                <NavDropdown.Item href="/contato">Contato</NavDropdown.Item>
                <NavDropdown.Item href="/cidades">Cidades</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/combos">Combos</Nav.Link>
              <Nav.Link href="/internet">Internet Fibra</Nav.Link>
              <Nav.Link href="/internet-rural">Internet Rural</Nav.Link>
              <Nav.Link href="/tvs">TV HD</Nav.Link>
              <Nav.Link href="/telefone">Telefonia Fixa</Nav.Link>
              <Nav.Link href="https://gigabytemultimidia.com.br/" target="_blank">Multimídia</Nav.Link>
              {/* <Nav.Link href="/telefone-movel">Móvel</Nav.Link> */}
              <Nav.Link href="/videos">Vídeos Explicativos</Nav.Link>
              <Nav.Link href="/curriculo">Trabalhe Conosco</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
