import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import Wifi from '../../../assets/images/www.png';
import INTERNET from '../../../assets/images/www.png';
import ContactForm from '../../../components/ContactForm/ContactForm';

import './Planos.css';

const plansData = {
  '10 MEGAS': [
    {
      plans: '10 MEGAS',
      description: 'Ideal para navegar tranquilamente e acessar as redes sociais',
      plus: '',
      dowload: '10Mbps de download',
      upload: '5Mbps de upload',
      price: 'R$ 60,00*/mês',
    }

  ],
  '60 MEGAS': [
    {
      plans: '60 MEGAS',
      description: 'Perfeito para streaming de vídeos e chamadas online sem interrupções.',
      plus: '',
      dowload: '60Mbps de download',
      upload: '30Mbps de upload',
      price: 'R$ 70,00*/mês',
    }
  ],
  '400 MEGAS': [
    {
      plans: '400 MEGAS',
      description: 'A escolha certa para gamers e streaming em alta definição.',
      plus: '',
      dowload: '400Mbps de download',
      upload: '200Mbps de upload',
      price: 'R$ 110,00*/mês',
    }

  ],
  '600 MEGAS': [
    {
      plans: '600 MEGAS',
      description: 'Velocidade máxima para múltiplos dispositivos e jogos online.',
      plus: '',
      dowload: '600Mbps de download',
      upload: '300Mbps de upload',
      price: 'R$ 130,00*/mês',
    }

  ],
};

const Planos = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState(null);

  const handleShowMoreInfo = (title) => {
    setSelectedPlan(title);
    setSelectedPlanData(plansData[title][0]); // Seleciona o primeiro item do array, assumindo que sempre haverá apenas um
    setShowMoreInfo(true);
  };

  const handleShowChannels = (title) => {
    setSelectedPlan(title);
    setShowChannels(true);
  };

  const handleClose = () => {
    setShowMoreInfo(false);
    setShowChannels(false);
  };

  return (
    <>
      <Container className="app-giga-play">
        <div className="text-center-title">Planos de Internet Fibra Óptica</div>
        <p>
          Desfrute da nossa Internet Fibra: velocidade e confiabilidade incomparáveis para sua experiência online perfeita. Navegue, assista e trabalhe sem interrupções.
        </p>
        <Row className="my-4">
          {Object.keys(plansData).map((title, index) => (
            <Col key={index} md={3} sm={6}>
              <Card className="internet-card">
                <Card.Img variant="top" src={Wifi} alt={title} className="internet-image" />
                <Card.Header>Internet Fibra Óptica</Card.Header>
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text className='internet-body'>
                    <strong><i className="fa fa-plus-square-o" aria-hidden="true"></i></strong> {plansData[title][0].description}<hr />
                    <strong><i className="fa fa-download" aria-hidden="true"></i></strong> {plansData[title][0].dowload}<hr />
                    <strong><i className="fa fa-upload" aria-hidden="true"></i></strong> {plansData[title][0].upload}<hr />
                    
                    <h2><strong>R$</strong> {plansData[title][0].price}</h2>
                    
                    <p>Desconto de R$10,00 na Internet para pagamento até a data de vencimento</p>
                  </Card.Text>
                  <Button className="mais-button" onClick={() => handleShowMoreInfo(title)}>
                    Mais informações
                  </Button> {' '}
                  <Button className="canais-button" onClick={() => handleShowChannels(title)}>
                    Assinar
                  </Button >
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal para Mais Informações */}
      <Modal show={showMoreInfo} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> <div className='internet-card'> <img src={INTERNET} alt='internet' /> {selectedPlan}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlanData && (
            <div>
              <h4>{selectedPlanData.description}</h4>
              <p><strong>Download:</strong> {selectedPlanData.dowload}</p>
              <p><strong>Upload:</strong> {selectedPlanData.upload}</p>
              <h2><strong>Preço:</strong> {selectedPlanData.price}</h2>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='plan-span'>
            <span>
              *Promoção válida para pagamento até a data do vencimento e adesão do plano conta sem papel.*
            </span>
            <span>
              *Oferta exclusiva para clientes Gigabyte Telecom, consulte condições de disponibilidade e instalação para novos clientes.*
            </span>
          </div>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Ver Canais */}
      <Modal show={showChannels} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Formulário de Contato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Planos;
