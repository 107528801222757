import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PhoneIcon from '../../../assets/icons/phone.png';
import PostItWhite from '../../../assets/icons/branco.png';
import PostItYellow from '../../../assets/icons/amarelo.png';
import PostItGreen from '../../../assets/icons/verde.png';
import PostItBlack from '../../../assets/icons/preto.png';
import PostItBlue from '../../../assets/icons/azul.png';
import PostItPink from '../../../assets/icons/rosa.png';
import './Plans.css'; // Assumindo que temos um arquivo CSS para estilos adicionais

const Plans = () => {
  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">Planos ideais pra você</h1>
      <p className="text-center">
        Faça parte da família <strong>#GIGA</strong>, escolha o plano perfeito para você.
      </p>

      <h2 className="mt-5"> <img src={PhoneIcon} alt='fone' /> Urbano</h2>
      <Row>
        <Col md={4} xl={3}>
          <Card className="blue-bg order-card">
            <Card.Body>
              <div className='icon-card'>
                <div className='post-it'><img src={PostItYellow} alt="alfinete" /></div>
              </div>
              <h6 className="m-b-2">LIGAÇÕES PARA QUALQUER OPERADORA</h6>
              <h2 className="text-right"><span> R$ 60,00*/MÊS</span></h2>
              <div className="m-b-0 observacao">LIGAÇÕES PARA LOCAL FIXO<span className="f-right"></span></div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xl={3}>
          <Card className="green-bg order-card">
            <Card.Body>
              <div className='icon-card'>
                <div className='post-it'><img src={PostItBlack} alt="alfinete" /></div>
              </div>
              <h6 className="m-b-2">NÃO LIGA PARA CELULARES E FIXO DE OUTRAS CIDADES</h6>
              <h2 className="text-right"><span> R$ 30,00*/MÊS</span></h2>
              <div className="m-b-0 observacao">LIGAÇÕES PARA LOCAL FIXO<span className="f-right"></span></div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xl={3}>
          <Card className="yellow-bg order-card">
            <Card.Body>
              <div className='icon-card'>
                <div className='post-it'><img src={PostItGreen} alt="alfinete" /></div>
              </div>
              <h6 className="m-b-2">BINA - IDENTIFICADOR DE CHAMADAS</h6>
              <h2 className="text-left"><span> R$ 5,00*/MÊS</span></h2>
              <div className="m-b-0 observacao">IDENTIFICADOR DE CHAMADAS PARA APARELHOS PADRÃO FSK<span className="f-right"></span></div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4} xl={3}>
          <Card className="red-bg order-card">
            <Card.Body>
              <div className='icon-card'>
                <div className='post-it'><img src={PostItWhite} alt="alfinete" /></div>
              </div>
              <h6 className="m-b-2">SIGAME</h6>
              <h2 className="text-left"><span> R$ 25,00*/MÊS</span></h2>
              <div className="m-b-0 observacao">REDIRECIONA A CHAMADA PARA O CELULAR DO CLIENTE!<span className="f-right"></span></div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className="mt-5"><img src={PhoneIcon} alt='fone' /> Rural</h2>
      <Row>
        <Col sm={12} md={6}>
          <Card className="purple-bg order-card">
            <Card.Body>
              <div className='icon-card'>
                <div className='post-it'><img src={PostItPink} alt="alfinete" /></div>
              </div>
              <h6 className="m-b-2">500 MINUTOS - LIGAÇÕES PARA QUALQUER OPERADORA</h6>
              <h2 className="text-left"><span> R$ 100,00*/MÊS</span></h2>
              <div className="m-b-0 observacao">LIGAÇÕES PARA LOCAL FIXO<span className="f-right"></span></div>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} md={6}>
          <Card className="pink-bg order-card">
            <Card.Body>
              <div className='icon-card'>
                <div className='post-it'><img src={PostItBlue} alt="alfinete" /></div>
              </div>
              <h6 className="m-b-2">PLANO COM BLOQUEIO</h6>
              <h2 className="text-left"><span> R$ 50,00*/MÊS</span></h2>
              <div className="m-b-0 observacao">LIGAÇÕES PARA LOCAL FIXO<span className="f-right"></span></div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Plans;
